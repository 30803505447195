import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import "focus-visible/dist/focus-visible.js"

// Components
import Hero from "../../components/hero"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
// import Stat from "../../components/stat"

// Assets

const PrivacyPolicy = ({ location }) => {
  const translation = {
    href: "/fr/politique-de-confidentialite",
    hrefLang: "fr",
  }

  const data = useStaticQuery(graphql`
    query {
      heroBg: file(
        relativePath: { eq: "hero-bg.jpg" }
        sourceInstanceName: { eq: "images" }
      ) {
        relativePath
        childImageSharp {
          fluid(maxWidth: 2000, maxHeight: 700, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  const heroImage = data.heroBg.childImageSharp

  return (
    <Layout lang="en" location={location} translation={translation}>
      <SEO title="Privacy policy | ABLV" description="" />

      {/* Hero */}
      <Hero uptitle="ABLV Group" title={`Privacy Policy`} image={heroImage} />

      <section className="legal">
        {/* Columns */}
        <div className="c-container">
          <div className="grid grid-cols-12 gap-4 lg:gap-8">
            {/* Column */}
            <div className="col-span-12 lg:max-w-none lg:col-span-8">
              <p>
                <b>This content is currently only available in French.</b>
              </p>
              <p>
                Ce document de politique de confidentialité contient les types
                d’informations qui sont collectées et enregistrées par ABLV
                Group SA, et la façon dont nous les utilisons.
              </p>
              <p>Version du 30 septembre 2022</p>
              <h2>1. Introduction</h2>
              <p>
                ABLV Group SA dont le siège est à St-Sulpice (ci-après&nbsp;:
                «&nbsp;<b>ABLV Group</b>&nbsp;», «&nbsp;<b>nous</b>&nbsp;»,
                «&nbsp;<b>notre</b>&nbsp;», «&nbsp;<b>nos</b>&nbsp;»), est une
                société qui détient des participations dans des sociétés suisses
                ; elle peut dans ce cadre exercer diverses activités et fournir
                des prestations (ci-après&nbsp;: «<b>Prestation(s)</b>&nbsp;»).
              </p>
              <p>
                Nous proposons également du contenu relatif à notre activité sur
                notre site web www.ablv.swiss (ci-après&nbsp;: «<b>Site</b>
                &nbsp;»)
              </p>
              <p>
                Afin de pouvoir fournir nos Prestations et les développer, ainsi
                que pour fournir du contenu sur notre Site, nous collectons,
                utilisons et communiquons un certain nombre de données
                personnelles vous concernant. Lorsque nous parlons de données
                personnelles, nous nous référons aux données personnelles au
                sens de la Loi fédérale sur la protection des données (LPD). Ce
                sont toutes les informations qui nous permettent de vous
                identifier immédiatement ou en les combinant avec d’autres
                informations. À cet égard, nous sommes le responsable du
                traitement des données personnelles.
              </p>
              <p>
                La présente politique de confidentialité (ci-après
                «&nbsp;Politique &nbsp;») expose les conditions dans lesquelles
                nous traitons des données vous concernant, ainsi que les choix à
                votre disposition s’agissant de la collecte, l’utilisation et la
                communication de ces données personnelles conformément à la LPD.
              </p>
              <p>
                En accédant au Site, vous reconnaissez avoir lu, compris et
                accepté d’être sujets à toutes les conditions prévues par cette
                Politique.
              </p>
              <h2>
                2. Traitement des données personnelles lorsque vous recourez à
                nos Prestations
              </h2>
              <h3>2.1. Données personnelles pouvant être traitées</h3>
              <p>
                Lorsque vous recourez à nos Prestations, il vous sera demandé de
                nous communiquer un certain nombre de données personnelles vous
                concernant comme vos coordonnées personnelles (par exemple nom,
                prénom, adresse, numéro de téléphone, adresse électronique,
                etc.), vos données de facturation (numéro de compte bancaire,
                IBAN, adresse de facturation, etc.), etc.
              </p>
              <p>
                Vous pouvez également, librement, nous communiquer des données
                supplémentaires.
              </p>
              <h3>2.2. But d’utilisation des données personnelles</h3>
              <h4>2.2.1. Fourniture des Prestations</h4>
              <p>
                Nous utilisons vos données personnelles pour fournir nos
                Prestations, interagir avec vous et répondre à vos demandes.
              </p>
              <h4>2.2.2. Poursuite de nos intérêts légitimes</h4>
              <p>
                Nous pouvons utiliser vos données personnelles pour poursuivre
                nos intérêts légitimes, ce qui inclut la détection des fraudes,
                le recouvrement de créances, ainsi que l’étude de toutes
                plaintes que nous pourrions recevoir, de votre part ou de tiers.
              </p>
              <h4>2.2.3. Respect de nos responsabilités légales</h4>
              <p>
                Nous utilisons vos données personnelles afin de nous acquitter
                de nos obligations légales, comme la conservation des documents
                comptables ou la réponse aux demandes d’autorités.
              </p>

              <h2>
                3. Traitement des données personnelles lorsque vous naviguez sur
                notre site
              </h2>
              <h3>3.1. Introduction</h3>
              <p>
                Lorsque vous naviguez sur notre Site et interagissez avec ce
                dernier, des technologies de collecte de données automatisées
                sont utilisées afin de recueillir certaines données personnelles
                vous concernant pour comprendre vos actions (p.&nbsp;ex. quels
                liens ont été cliqués, quelles pages ont été visualisées), ainsi
                que d’autres données et statistiques similaires (p.&nbsp;ex. les
                temps de réponse des contenus, les erreurs de téléchargement, la
                durée de visite de certaines pages, le type de navigateur
                utilisé ou le lieu de connexion). Ces informations sont saisies
                au moyen des technologies automatisées comme les cookies.
              </p>
              <h3>3.2. Cookies et balises web</h3>
              <h4>3.2.1. Données personnelles pouvant être traitées</h4>
              <p>
                Les cookies se présentent sous forme de fichiers textes capables
                d’identifier les visiteurs et de sauvegarder leurs préférences
                personnelles (p.&nbsp;ex. leur choix linguistique) ainsi que des
                informations d’ordre technique (y. c. des données de type clics
                et de type parcours).
              </p>
              <p>
                Les balises web, ou clear GIF, sont de très petites images
                transparentes (1 pixel par 1 pixel) intégrées dans un site web.
                Elles permettent de collecter des informations sur l’activité
                des visiteurs du site, par exemple le système d’exploitation
                utilisé, le navigateur utilisé, l’adresse IP ou encore le temps
                passé sur le site.
              </p>
              <h4>3.2.2. But d’utilisation des données personnelles</h4>
              <p>
                Nous utilisons des cookies et des balises web pour faciliter
                l’accès au Site, comprendre la façon dont vous naviguez sur
                notre site et repérer les dysfonctionnements du Site. Cela nous
                permet aussi d’améliorer votre expérience ainsi que la
                conception et le contenu du Site.
              </p>
              <h4>3.2.3. Collaboration avec des entreprises tierces</h4>
              <p>
                Pour l’exploitation du Site, nous pouvons être amenés à
                collaborer avec des entreprises tierces qui placent des cookies
                ou des balises web sur le Site. Ces entreprises nous aident dans
                la gestion du Site et proposent des produits et services
                supplémentaires. L’utilisation de ces technologies par ces tiers
                est alors soumise à leurs propres conditions de traitement des
                données personnelles et n’est pas couverte par la Politique.
              </p>
              <h4>3.2.4. Désactivation des cookies</h4>
              <p>
                L’utilisation de cookies peut être désactivée en sélectionnant
                les paramètres appropriés au sein du navigateur utilisé.
                Cependant, une telle désactivation pourrait empêcher
                l'utilisation de certaines fonctionnalités de ce Site. Pour plus
                d’informations sur les cookies&nbsp;:{" "}
                <a
                  href="https://allaboutcookies.org/fr"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  allaboutcookies.org/fr
                </a>{" "}
                et{" "}
                <a
                  href="https://youronlinechoices.com/fr"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  youronlinechoices.com/fr
                </a>
                .
              </p>
              <p>
                Selon le navigateur utilisé, la désactivation de Google
                Analytics est aussi possible en installant une extension sur le
                navigateur ou en consultant{" "}
                <a
                  href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=fr"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  cette marche à suivre
                </a>{" "}
                (à noter que cette marche à suivre permet de désactiver
                l’utilisation de cookies dans le navigateur). Vous avez
                également la possibilité de consulter{" "}
                <a
                  href="https://policies.google.com/technologies/partner-sites?gl=fr"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  l’utilisation de vos données personnelles
                </a>{" "}
                lors du recours à Google Analytics.
              </p>
              <p>
                Vous pouvez également en tout temps modifier ou retirer votre
                consentement pour les cookies non-essentiels en cliquant sur{" "}
                <a href="#cookies">ce lien</a>.
              </p>
              <h2>4. Communication des données personnelles</h2>
              <h3>4.1. Sous-traitance</h3>
              <p>
                Nous pouvons recourir à des prestataires externes
                (sous-traitants) en particulier des prestataires pour&nbsp;:
              </p>
              <ul>
                <li>
                  gérer et/ou maintenir le Site, analyser le trafic sur le Site,
                  envoyer des messages pour notre compte, mener des recherches,
                  surveiller et analyser le statut de notre réseau et
                  l’efficacité de notre communication.
                </li>
                <li>
                  sous-traiter des activités en lien avec les prestations que
                  nous offrons.
                </li>
              </ul>
              <p>
                Des données personnelles ne leur sont communiquées que dans la
                limite nécessaire à la réalisation des tâches qui leur sont
                confiées.
              </p>
              <h3>4.2. Communication à des tiers</h3>
              <p>
                Nous pouvons transmettre vos données personnelles à des tiers
                (i)&nbsp;si cela est nécessaire à la poursuite des buts pour
                lesquels vous nous les avez librement transmises, (ii)&nbsp;si
                vous y avez préalablement consenti ou (iii) si la loi nous le
                permet ou nous y oblige (p.&nbsp;ex. sur requête d’une autorité
                judiciaire, pour faire valoir nos droits en justice ou pour
                procéder au recouvrement d’une créance avec l’aide d’un service
                de recouvrement).
              </p>
              <h3>4.3. Transfert à l’étranger</h3>
              <p>
                Les données personnelles sont traitées en Suisse ainsi que dans
                les pays de l’UE.
              </p>
              <p>
                Les données peuvent aussi être envoyées vers d’autres États
                lorsque cela est opportun. La Société respectera alors les
                règles applicables et adoptera les mesures requises et
                nécessaires préalablement à la transmission de données
                personnelles à l’étranger.
              </p>
              <h2>
                5. Sécurité et confidentialité des données personnelles, durée
                de conservation
              </h2>
              <p>
                Bien que la sécurité totale n’existe pas, des mesures de
                sécurité (d’ordre technique et organisationnel) conformes aux
                règles de l’art ont été mises en place, compte tenu des risques
                encourus, pour protéger vos données personnelles contre toute
                manipulation accidentelle ou intentionnelle, perte, destruction,
                communication ou contre tout accès non autorisé.
              </p>
              <p>
                Les données personnelles sont conservées aussi longtemps que
                nécessaire au regard des finalités du traitement. Elles peuvent
                être conservées plus longtemps lorsqu’il existe une obligatoire
                légale de conserver les données.
              </p>
              <h2>6. Droits</h2>
              <p>
                Vous avez le droit d’être renseigné gratuitement sur les données
                personnelles enregistrées à votre sujet et, lorsque celles-ci
                apparaissent comme inexactes ou incomplètes, le droit de les
                rectifier ou de les supprimer. Vous avez également un droit
                d’opposition au traitement des données personnelles vous
                concernant, sous réserve de motifs justifiant le traitement.
              </p>
              <p>
                Si vous désirez exercer l’un de ces droits, vous pouvez nous
                contacter en écrivant par courrier à l’adresse&nbsp;: ABLV Group
                SA, rue des Jordils 40, 1025 St-Sulpice, ou par courriel à
                l’adresse &nbsp;:{" "}
                <a href="mailto:info@ablv.swiss">info@ablv.swiss</a>. Dans tous
                les cas, une preuve de votre identité pourra être demandée.
              </p>
              <h2>7. For et droit applicable</h2>
              <p>
                La Politique et toutes questions en découlant ou liées à
                celle-ci, ainsi qu’à l’utilisation de notre Site ou au recours à
                nos Prestations, sont régies par le droit suisse, à l’exclusion
                des règles en matière de conflit de lois.
              </p>
              <p>
                Vous acceptez la compétence exclusive des Tribunaux du siège de
                ABLV Group SA.
              </p>
              <h2>8. Mise à jour de notre Politique</h2>
              <p>
                Nous pouvons mettre à jour cette Politique de temps en temps
                sans préavis.
              </p>
              <p>
                Nous chercherons à attirer votre attention sur toute
                modification significative. Si vous nous avez transmis votre
                adresse électronique, vous serez le cas échéant informé par
                courriel de la nouvelle Politique. La nouvelle Politique sera
                également disponible sur le Site.
              </p>
              <p>
                Le recours à nos Prestations après une modification de la
                Politique est une acceptation explicite des modifications, au
                même titre que l’utilisation continue de notre Site.
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default PrivacyPolicy
